<template>
  <div class="container-fluid supplier-content">
    <template v-if="error">
      <error-notice :code="404"></error-notice>
    </template>
    <template v-else>
      <div class="skinny row">
        <div class="col-md-6 col-sm-10">
          <h1>
            Tu proyecto ha sido
            <span>creado</span>.
          </h1>
        </div>
      </div>
      <div class="skinny row">
        <div class="col-md-6 col-sm-10">
          <h3>¡Felicidades! Has creado un proyecto semilla.</h3>
          <br />
          <label>
            <strong>ID de proyecto:</strong>
            {{id}}
          </label>
          <br />
          <label>
            El proyecto está en revisión para ser pre-aprobado,
            pero pordrás completar la solicitud si así lo deseas.
          </label>
          <br />
          <label>
            Recuerda que mientras más información tengamos,
            mayor es la probabilidad de aprobación.
          </label>
          <div class="alt-1"></div>
          <button class="btn btn-ins" @click="goToProject()">Completar solicitud</button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  params: ["projectId"],
  data() {
    return {
      error: false
    };
  },
  computed: {
    id: function(){
      return this.$route.params.id;
    }
  },
  methods: {
    goToProject() {
      this.$router.replace({
        name: "detalleProyecto",
        params: { id: this.id }
      });
    }
  }
};
</script>